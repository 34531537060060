import { createI18n } from 'vue-i18n'

import enGB from '@/locales/en-GB.json'
import enUS from '@/locales/en-US.json'
import svSE from '@/locales/sv-SE.json'

type MessageSchema = typeof enGB

// Custom Intl formats to be used when calling "$d()" or using component "<i18n-d>"
const datetimeFormats = {
  shortDate: { dateStyle: 'short' },
  mediumDate: { dateStyle: 'medium' },
  longDate: { dateStyle: 'long' },
  fullDate: { dateStyle: 'full' },

  shortTime: { timeStyle: 'short' },
  mediumTime: { timeStyle: 'medium' },
  longTime: { timeStyle: 'long' },
  fullTime: { timeStyle: 'full' },

  shortDatetime: { dateStyle: 'short', timeStyle: 'short' },
  mediumDatetime: { dateStyle: 'medium', timeStyle: 'medium' },
  longDatetime: { dateStyle: 'long', timeStyle: 'long' },
  fullDatetime: { dateStyle: 'full', timeStyle: 'full' },
} as const

// We pass "false" as the third type argument to get proper type inference for the i18n instance.
// We also need to pass legacy: false in the options object to disable the legacy mode.
// https://vue-i18n.intlify.dev/guide/advanced/typescript.html#typescript-support
const i18n = createI18n<MessageSchema, 'en-GB' | 'en-US' | 'sv-SE', false>({
  legacy: false,
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  globalInjection: true,
  silentFallbackWarn: true, // Suppressing warning noise for the time being
  silentTranslationWarn: true, // Suppressing warning noise for the time being
  messages: {
    'en-GB': enGB,
    'en-US': enUS,
    'sv-SE': svSE,
  },
  datetimeFormats: {
    'en-GB': datetimeFormats,
    'en-US': datetimeFormats,
    'sv-SE': datetimeFormats,
  },
})

export default i18n
