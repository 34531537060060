import { ItemType } from '@/services/parsers/Shop/ItemTypeParser'

export interface MandatoryCopayInterval {
  age: number
  copay: number
}

export interface CatalogProduct {
  type: ItemType
  tags: string[]
  displayName: string
  features: CatalogFeature[]
  compatibilityGroups: string[]
  mandatoryCopayIntervals: MandatoryCopayInterval[]

  tier: string

  excess?: number
  copay?: number
  limit?: number
}
export interface CatalogOption {
  type: 'option'
  displayName: string
  optionType: string
  features: CatalogFeature[]

  excess?: number
  copay?: number
  limit?: number
}

export interface CatalogFeature {
  displayName: string
  featureType: string

  excess?: number
  copay?: number
  limit?: number
}

export type CatalogSurcharges = Record<string, string>

export type ProductCatalogElement =
  | CatalogProduct
  | CatalogOption
  | CatalogSurcharges

export type ProductCatalog = Map<string, ProductCatalogElement>

export const isCatalogProduct = (
  product: ProductCatalogElement
): product is CatalogProduct => {
  return product.type !== 'option'
}

export const isCatalogOption = (
  product: ProductCatalogElement
): product is CatalogOption => {
  return product.type === 'option'
}

export const isCatalogSurcharge = (
  product: ProductCatalogElement
): product is CatalogSurcharges => {
  return !product.type
}
