import {
  getWebInstrumentations,
  initializeFaro,
  type InternalLoggerLevel,
} from '@grafana/faro-web-sdk'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'

import App from '@/App.vue'
import auth0 from '@/auth0'
import { inputAutoFocus } from '@/directives'
import i18n from '@/i18n'
import { getBuildContext, getBuildCommitRef } from '@/lib/build'
import { readactEmailIfExists } from '@/lib/Redact'
import router from '@/lib/routes'
import { plrn, instance, agency } from '@/plrn'
import ElementPlugin from '@/plugins/ElementPlugin'
import ErrorPlugin from '@/plugins/ErrorPlugin'
import MuttPlugin from '@/plugins/MuttPlugin'

// Didn't used to have to do this but now we do. Didn't want to put it in each
// component as it may come in a few times.
import 'vue-json-pretty/lib/styles.css'

const app = createApp(App)

// Create pinia instance and add persisted state plugin
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

app.provide('plrn', plrn)
app.provide('instance', instance)
app.provide('agency', agency)
app.provide('auto0', auth0)

if (import.meta.env.VITE_FARO_URL) {
  initializeFaro({
    url: import.meta.env.VITE_FARO_URL,
    app: {
      name: import.meta.env.VITE_FARO_APP_NAME,
      version: getBuildCommitRef(),
      environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    },
    internalLoggerLevel: import.meta.env
      .VITE_FARO_LOGGER_LEVEL as InternalLoggerLevel,

    instrumentations: [...getWebInstrumentations()],
  })
}

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    initialScope: {
      tags: {
        'deploy.context': getBuildContext(),
      },
    },

    beforeSend(event) {
      try {
        const json = JSON.stringify(event)
        const redactedEventData = readactEmailIfExists(json)

        return JSON.parse(redactedEventData)
      } catch {
        return event
      }
    },

    beforeBreadcrumb: (breadcrumb) => {
      // Filter out LD metrics HTTP requests
      if (breadcrumb.type === 'http') {
        if (breadcrumb.data?.url.includes('events.launchdarkly.com')) {
          return null
        }
      }

      try {
        if (
          breadcrumb?.category === 'ui.click' ||
          breadcrumb?.category === 'ui.input'
        ) {
          if (breadcrumb.data && typeof breadcrumb.data.element === 'string') {
            const bufferElement = document.createElement('div')
            bufferElement.innerHTML = breadcrumb.data.element

            bufferElement
              .querySelectorAll('.pcaitem')
              .forEach((targetElement) => {
                while (targetElement.attributes.length > 0) {
                  targetElement.removeAttribute(
                    targetElement.attributes[0].name
                  )
                }
              })

            breadcrumb.data.element = bufferElement.innerHTML
          }
        }
      } catch (error) {
        console.error('Error sanitizing breadcrumb:', error)
        return null
      }

      return breadcrumb
    },
    trackComponents: false,
    logErrors: true,
    tracesSampleRate: 0.2,
    maxBreadcrumbs: 200, // increase from default of 100 to 200
    normalizeDepth: 5, // Increase from default of 3 to 5
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    release: getBuildCommitRef(),
    ignoreErrors: ['ResizeObserver'],
    denyUrls: [],
  })
}

app.use(i18n)
app.use(router)
app.use(MuttPlugin)
app.use(ElementPlugin)
app.use(ErrorPlugin)
app.use(auth0)
app.use(pinia)
app.directive('input-auto-focus', inputAutoFocus)

app.mount('#app')
