import { PaymentSchedule } from '@/domain/Account/AccountSummary'
import {
  Order,
  OrderAction,
  OrderCustomer,
  OrderCustomerAddress,
  OrderItem,
  OrderItemOption,
  OrderPet,
  OrderProduct,
  OrderSubscription,
} from '@/domain/Order'
import { getConfigForFeature } from '@/lib/appConfig'

import { PaymentScheduleJson } from '../Account/AccountParser'

import { CustomerParser } from './CustomerParser'
import { ItemTypeParser } from './ItemTypeParser'
import OrderPaymentsAdapter from './OrderPaymentsAdapter'
import PetParser from './PetParser'
import ProductPriceParser, {
  ProductDeltaPriceJson,
  SubscriptionPriceJson,
} from './ProductPriceParser'

export interface OrderJson {
  id: string
  x_basket_id?: string
  token: string
  pets?: OrderPetJson[]
  items?: OrderItemJson[]
  price: ProductDeltaPriceJson
  customer: OrderCustomerJson
  effective_date?: string
  cessation_date?: string
  subscription?: OrderSubscriptionJson
  operation_type: 'add' | 'edit' | 'edit_payment' | 'remove' | 'lapse' | 'renew'
}

interface OrderBreedJson {
  display_name: string
  value: string
}

export type OrderPetJson = OrderPetNewJson | OrderPetExistingJson

export interface OrderPetNewJson {
  id: string
  display_name: string
  gender: string
  dob: string
  species: string
  breed: OrderBreedJson
  pedigree_type: string
  postcode: string
  state?: string
  spayed_neutered?: boolean
  value?: number
}

export interface OrderPetExistingJson {
  id: string
  display_name: string
  name?: string
  gender?: string
  dob?: string
  species?: string
  breed?: OrderBreedJson
  pedigree_type?: string
  postcode?: string
  state?: string
  spayed_neutered?: boolean
  value?: number
}

export interface OrderItemProductJson {
  sku: string
  price: ProductDeltaPriceJson
}

export interface OrderItemOptionJson {
  sku: string
  price: ProductDeltaPriceJson
}

interface OrderItemJson {
  pet: string
  action: string
  end_date?: string
  type: string
  options?: OrderItemOptionJson[]
  product: OrderItemProductJson
  price: ProductDeltaPriceJson
  moneyback?: {
    expected_amount: number
  }
  reason?: string
  x_quote?: string
}

type OrderCustomerJson = OrderCustomerNewJson | OrderCustomerExisitingJson

export interface OrderCustomerNewJson {
  id?: never
  email: string
  title?: string
  first_name?: string
  last_name?: string
  address?: OrderCustomerAddressJson
  phone?: string
  dob?: string
  ssn?: string
}

export interface OrderCustomerExisitingJson {
  id: string
  email?: string
  title?: string
  first_name?: string
  last_name?: string
  address?: OrderCustomerAddressJson
  phone?: string
  dob?: string
  ssn?: string
}

interface OrderCustomerAddressJson {
  line1?: string
  line2?: string
  city?: string
  state_county?: string
  country?: string
  postcode?: string
}

export interface OrderSubscriptionJson {
  payment_day: number
  payment_frequency: 'monthly' | 'annual'
  payment_method?: Nullable<'direct_debit' | 'card'>
  price: SubscriptionPriceJson
  next_payment?: OrderNextPaymentJson
  schedule?: PaymentScheduleJson[]
}

interface OrderNextPaymentJson {
  date?: string
  total?: number
}

export class OrderParser {
  public static parse(data: OrderJson): Order {
    const basketVersion = data.x_basket_id?.startsWith('bas_')
      ? 1
      : data.x_basket_id?.startsWith('bkt_')
        ? 2
        : undefined

    const result: Order = {
      id: data.id,
      basketId: data.x_basket_id,
      basketVersion,
      token: data.token,
      pets: OrderParser.parsePets(data.pets ?? [], data.items ?? []),
      price: ProductPriceParser.parseDeltaPrice(data.price),
      customer: OrderParser.parseCustomer(data.customer),
      isNewCustomer: OrderParser.isNewCustomer(data.customer),
      effectiveDate: data.effective_date,
      cessationDate: data.cessation_date,
      subscription:
        data.subscription && OrderParser.parseSubscription(data.subscription),
      operationType: data.operation_type,
    }

    if (getConfigForFeature('flags.enableRetainedPremiumsCalculations')) {
      return OrderPaymentsAdapter.adaptPayments(result)
    }

    return result
  }

  private static parseCustomer(
    data: OrderCustomerNewJson | OrderCustomerExisitingJson
  ): OrderCustomer {
    const customer = {
      title: data.title ? CustomerParser.parseTitle(data.title) : undefined,
      firstName: data.first_name,
      lastName: data.last_name,
      address: data.address && OrderParser.parseCustomerAddress(data.address),
      phone: data.phone,
      dateOfBirth: data.dob,
      ssn: data.ssn,
    }
    if (this.isNewCustomer(data)) {
      return {
        ...customer,
        email: data.email,
      }
    }
    return {
      ...customer,
      id: data.id,
      email: data.email,
    }
  }

  private static parseCustomerAddress(
    data: OrderCustomerAddressJson
  ): OrderCustomerAddress {
    return {
      line1: data.line1,
      line2: data.line2,
      city: data.city,
      stateOrCounty: data.state_county,
      country: data.country,
      postcode: data.postcode,
    }
  }

  public static parseSubscription(
    data: OrderSubscriptionJson
  ): OrderSubscription {
    return {
      paymentDay: data.payment_day,
      paymentFrequency: data.payment_frequency,
      paymentMethod: data.payment_method ?? undefined,
      nextPayment: {
        date: data.next_payment?.date,
        total: data.next_payment?.total ?? data.price.monthly_total.total,
      },
      price: ProductPriceParser.parseSubscriptionPrice(data.price),
      schedule: data?.schedule?.map(OrderParser.parsePaymentSchedule) ?? [],
    }
  }

  public static parsePaymentSchedule(
    data: PaymentScheduleJson
  ): PaymentSchedule {
    return {
      due: data.due,
      total: data.total,
      type: data.type,
      coverPeriod: data.cover_period
        ? {
            startDate: data.cover_period.start_date,
            endDate: data.cover_period.end_date,
          }
        : null,
    }
  }

  private static parsePets(
    pets: OrderPetJson[],
    items: OrderItemJson[]
  ): OrderPet[] {
    return pets.map((pet) => OrderParser.parsePet(pet, items))
  }

  private static parsePet(pet: OrderPetJson, items: OrderItemJson[]): OrderPet {
    const result: OrderPet = {
      id: pet.id,
      name: pet.display_name,
      items: OrderParser.parseItemsForPet(pet.id, items),
      dateOfBirth: pet.dob,
      breed: pet.breed?.value,
      breedLabel: pet.breed?.display_name,
      species: pet.species ? PetParser.parseSpecies(pet.species) : undefined,
      gender: pet.gender ? PetParser.parsePetGender(pet.gender) : undefined,
      pedigreeType: pet.pedigree_type
        ? PetParser.parsePedigreeType(pet.pedigree_type)
        : undefined,
      postcode: pet.postcode,
      spayedNeutered: pet.spayed_neutered,
      value: pet.value,
    }

    if (pet.state) {
      result.state = pet.state
    }

    return result
  }

  private static parseItemsForPet(
    petId: string,
    items: OrderItemJson[]
  ): OrderItem[] {
    return items.filter((item) => item.pet === petId).map(OrderParser.parseItem)
  }

  private static parseItemProduct(product: OrderItemProductJson): OrderProduct {
    return {
      sku: product.sku,
      price: ProductPriceParser.parseDeltaPrice(product.price),
    }
  }

  private static parseItemOptions(
    options: OrderItemOptionJson[] | undefined
  ): OrderItemOption[] {
    if (!options) {
      return []
    }

    return options?.map((option: OrderItemOptionJson) => {
      return {
        sku: option.sku,
        price: ProductPriceParser.parseDeltaPrice(option.price),
      }
    })
  }

  private static parseItem(data: OrderItemJson): OrderItem {
    return {
      id: `${data.pet}_${data.type}`,
      pet: data.pet,
      action: OrderParser.parseItemAction(data.action),
      endDate: data.end_date,
      type: ItemTypeParser.parse(data.type),
      options: OrderParser.parseItemOptions(data.options),
      product: OrderParser.parseItemProduct(data.product),
      price: ProductPriceParser.parseDeltaPrice(data.price),
      moneyback: data.moneyback
        ? { expectedAmount: data.moneyback.expected_amount }
        : undefined,
      reason: data.reason,
      externalQuoteId: data.x_quote,
    }
  }
  private static parseItemAction(action: string): OrderAction {
    switch (action) {
      case 'create':
      case 'adjust':
      case 'remove':
      case 'renewal':
        return action
      default:
        throw new Error(`Unknown item action: ${action}`)
    }
  }

  private static isNewCustomer(
    customer: OrderCustomerNewJson | OrderCustomerExisitingJson
  ): customer is OrderCustomerNewJson {
    return !customer.id
  }
}
