import {
  apiClient,
  shopCompatibilityModeApiClient,
  accountApiClient,
  ApiClientOptions,
  ApiClient,
  shopApiClient,
  shopApiClientNoAuth,
  certificateApiClient,
  lunaApiClient,
} from '@/lib/api/ApiClient'

interface FetchConfig {
  path: string
  params?: ApiClientOptions
}

interface ParsableFetchConfig<I, O> extends FetchConfig {
  parse(jsonData: I): O
}

const isParsableFetchConfig = <I, O>(
  config: FetchConfig | ParsableFetchConfig<I, O>
): config is ParsableFetchConfig<I, O> => {
  return Boolean((config as ParsableFetchConfig<I, O>).parse)
}

class ApiClientWrapper {
  private client: ApiClient

  constructor(client: ApiClient) {
    this.client = client
  }

  public async get<I, O>(config: ParsableFetchConfig<I, O>): Promise<O> {
    const json = await this.client.get(config.path, config.params).json<I>()

    return config.parse(json)
  }

  public async getAsResponse(config: FetchConfig): Promise<Response> {
    return await this.client.get(config.path, config.params)
  }

  private async send<I, O>(
    config: FetchConfig | ParsableFetchConfig<I, O>,
    sender: (path: string, params?: ApiClientOptions) => Promise<Response>
  ): Promise<O | Response> {
    const response = await sender(config.path, config.params)

    if (isParsableFetchConfig(config) && response.status !== 204) {
      const text = await response.text()

      // do not try to parse empty responses
      if (text) {
        const json = JSON.parse(text)
        return config.parse(json)
      }
    }

    return response
  }

  public async patch(config: FetchConfig): Promise<Response>
  public async patch<I, O>(config: ParsableFetchConfig<I, O>): Promise<O>

  public async patch<I, O>(
    config: FetchConfig | ParsableFetchConfig<I, O>
  ): Promise<O | Response> {
    return await this.send(config, this.client.patch)
  }

  public async post(config: FetchConfig): Promise<Response>
  public async post<I, O>(config: ParsableFetchConfig<I, O>): Promise<O>

  public async post<I, O>(
    config: FetchConfig | ParsableFetchConfig<I, O>
  ): Promise<O | Response> {
    return await this.send(config, this.client.post)
  }

  public async delete(config: FetchConfig): Promise<Response>
  public async delete<I, O>(config: ParsableFetchConfig<I, O>): Promise<O>

  public async delete<I, O>(
    config: FetchConfig | ParsableFetchConfig<I, O>
  ): Promise<O | Response> {
    return await this.send(config, this.client.delete)
  }

  public async put(config: FetchConfig): Promise<Response>
  public async put<I, O>(config: ParsableFetchConfig<I, O>): Promise<O>

  public async put<I, O>(
    config: FetchConfig | ParsableFetchConfig<I, O>
  ): Promise<O | Response> {
    const response = await this.client.put(config.path, config.params)

    if (isParsableFetchConfig(config) && response.status !== 204) {
      const json: I = await response.json()
      return config.parse(json)
    }

    return response
  }
}

export const ApiService = new ApiClientWrapper(apiClient)
export const ShopCompatibilityModeApiService = new ApiClientWrapper(
  shopCompatibilityModeApiClient
)
export const ShopApiService = new ApiClientWrapper(shopApiClient)
export const ShopApiServiceNoAuth = new ApiClientWrapper(shopApiClientNoAuth)
export const AccountApiService = new ApiClientWrapper(accountApiClient)
export const CertificateApiService = new ApiClientWrapper(certificateApiClient)
export const LunaApiService = new ApiClientWrapper(lunaApiClient)
export default ApiService
