import { faro, createSession, type MetaSession } from '@grafana/faro-web-sdk'
import * as Sentry from '@sentry/vue'

import type { HeapUserProperties } from '@/@types/Heap'

interface IdentityContext {
  userId?: string
  plrn: string
  agency: string
  instance: string
  roles: string[]
  permissions: string[]
  buildVersion: string
  ldConfig: Record<string, any>
  isDeveloper: boolean
  isCustomerSupportUser: boolean
  isClaimsUser: boolean
}

/**
 * @param {IdentityContext} context The vuex state
 */
export function setSentryContext(context: IdentityContext): void {
  const scope = Sentry.getCurrentScope()
  scope.setTag('instance', context.instance)
  scope.setTag('agency', context.agency)
  scope.setExtra('instance', context.instance)
  scope.setExtra('agency', context.agency)
  scope.setExtra('plrn', context.plrn)
  scope.setExtra('isDeveloper', context.isDeveloper)
  scope.setExtra('isCustomerSupportUser', context.isCustomerSupportUser)
  scope.setExtra('isClaimsUser', context.isClaimsUser)
  if (context.userId) {
    scope.setUser({ id: context.userId })
  }
  scope.setExtra('roles', context.roles)
  scope.setExtra('permissions', context.permissions)
  scope.setExtra('ldConfig', context.ldConfig)
}

export function setFaroContext(context: IdentityContext): void {
  const sessionData: MetaSession['attributes'] = {
    instance: context.instance,
    agency: context.agency,
    plrn: context.plrn,
    isDeveloper: String(context.isDeveloper),
    isCustomerSupportUser: String(context.isCustomerSupportUser),
    isClaimsUser: String(context.isClaimsUser),
  }

  faro.api?.setSession(createSession(sessionData))

  faro.api?.setUser({
    id: context.userId,
    roles: context.roles.join(','),
    username: context.userId,
    attributes: {
      permissions: context.permissions.join(','),
    },
  })
}

export function setHeapContext(context: IdentityContext): void {
  if (window.heap && context.userId) {
    window.heap.identify(`user-${context.userId}`) // prefix the userId as heap doesn't seem to like the userId format on its own and will throw an error. We should still be able to search for the userId in heap and Sentry

    // As Heap only stores the most recent values, these will change when switching agency which will affect metrics
    // This shouldn't be a problem for CSEs/CHs as they'll only be using one instance/agency at a time and we use separate Heap
    // environments for each instance.
    const userProperties: HeapUserProperties = {
      Instance: context.instance,
      Agency: context.agency,
      PLRN: context.plrn,
      Roles: context.roles.join(','),
      Permissions: context.permissions.join(','),
      Developer: context.isDeveloper,
      'Customer Support User': context.isCustomerSupportUser,
      'Claims User': context.isClaimsUser,
    }

    Object.entries(context.ldConfig).forEach(([key, value]) => {
      userProperties[`LD FF: ${key}`] = value
    })

    window.heap.addUserProperties(userProperties)
  }
}
