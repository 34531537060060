import {
  cloneDeep,
  sum,
  sumBy,
  startCase,
  uniq,
  uniqBy,
  sortBy,
} from 'lodash-es'

import { isNotNullOrUndefined } from '@/lib/utils/arrays'
import type {
  Calculation,
  CalculationCategory,
  CalculationTerm,
  CalculationTermSubcoverageItem,
  CalculationStatus,
} from '@/novelClaims/domain/Calculation'
import type { Loss, LossPayee } from '@/novelClaims/domain/Loss'
import {
  getLossPayeeVetName,
  getLossRefFromId,
} from '@/novelClaims/utils/claimUtils'

export function isCalculationExisting(
  calculation: Partial<Calculation>
): calculation is Calculation {
  return Boolean(calculation?.id)
}

export function isCalculationNew(calculation: Partial<Calculation>): boolean {
  return !isCalculationExisting(calculation)
}

export function isCalculationWithdrawn(
  calculation: Partial<Calculation>
): boolean {
  return !!calculation.withdrawn_by
}

export function isCalculationAssessed(
  calculation: Partial<Calculation>
): boolean {
  return !!calculation.assessment_result
}

export function isCalculationAccepted(
  calculation: Partial<Calculation>
): boolean {
  return calculation?.assessment_result === 'ACCEPTED'
}

export function isCalculationRejected(
  calculation: Partial<Calculation>
): boolean {
  return calculation?.assessment_result === 'REJECTED'
}

export function isCalculationReadyForReview(
  calculation: Partial<Calculation>
): boolean {
  return (
    !isCalculationWithdrawn(calculation) && !isCalculationAssessed(calculation)
  )
}

export function getCalculationStatus(
  calculation: Partial<Calculation>
): CalculationStatus {
  if (calculation?.assessment_result === 'ACCEPTED') {
    return 'ACCEPTED'
  }

  if (calculation?.assessment_result === 'REJECTED') {
    return 'REJECTED'
  }

  if (calculation.withdrawn_by) {
    return 'WITHDRAWN'
  }

  return 'READY_FOR_REVIEW'
}

export function getCalculationHandler(handler: string): string {
  if (handler === 'CIPIT') {
    return 'Millie'
  }

  return handler
}

export function isCalculationApprovedByMillie(
  calculation: Partial<Calculation>
): boolean {
  return (
    isCalculationAccepted(calculation) && calculation.assessed_by === 'CIPIT'
  )
}

export function isCalculationWithdrawnByMillie(
  calculation: Partial<Calculation>
): boolean {
  return (
    isCalculationWithdrawn(calculation) && calculation.withdrawn_by === 'CIPIT'
  )
}

export function getCalculationSubcoveragesTotals(
  calculation: Partial<Calculation>
): CalculationTermSubcoverageItem[] {
  const terms = calculation.terms ?? []
  const subcoveragesTotalsMap: Record<string, number> = {}

  terms.forEach((term) => {
    term.subcoverages?.forEach((subcoverage) => {
      subcoveragesTotalsMap[subcoverage.name] =
        (subcoveragesTotalsMap[subcoverage.name] ?? 0) + subcoverage.amount
    })
  })

  return sortBy(
    Object.entries(subcoveragesTotalsMap).map(([name, amount]) => ({
      name,
      amount,
    })),
    'name'
  )
}

export function getTermByCategory(
  terms: CalculationTerm[],
  category: CalculationCategory
): Maybe<CalculationTerm> {
  return terms.find((term) => term.category === category)
}

export function getTermsByCategory(
  terms: CalculationTerm[],
  category: CalculationCategory
): CalculationTerm[] {
  return terms.filter((term) => term.category === category)
}

export function getCalculationAmountsWarning(
  calculation: Partial<Calculation>
): string {
  const terms = calculation.terms ?? []
  const coverages = uniq(terms.map((term) => term.coverage))

  for (const coverage of coverages) {
    const coverageTerms = terms.filter((term) => term.coverage === coverage)
    const coverageTotal = sumBy(coverageTerms, 'amount')

    const subcoveragesTotalMap: Record<string, number> = {}

    for (const term of coverageTerms) {
      const termSubcoverages = term.subcoverages ?? []

      for (const subcoverage of termSubcoverages) {
        subcoveragesTotalMap[subcoverage.name] =
          (subcoveragesTotalMap[subcoverage.name] ?? 0) + subcoverage.amount
      }
    }

    const subcoveragesTotal = sum(Object.values(subcoveragesTotalMap))

    if (coverageTotal < 0) {
      return 'Coverage total cannot be negative'
    }

    if (Object.values(subcoveragesTotalMap).some((amount) => amount < 0)) {
      return 'Subcoverage total cannot be negative'
    }

    if (subcoveragesTotal > coverageTotal) {
      return 'Subcoverages total cannot be greater than coverage total'
    }
  }

  return ''
}

////////////////////////////////////////////////////////////////////////////////
// COVERAGE GROUPED CALCULATIONS
////////////////////////////////////////////////////////////////////////////////
export interface CoverageGroupedCalculation {
  coverages: CoverageGroupedCalculationCoverage[]
  lossesAmount: number
  totalAmount: number

  unattributed?: CoverageGroupedCalculationUnattributed
}

export interface CoverageGroupedCalculationCoverage {
  coverage: string

  lossItems: {
    lossId: string
    lossRef: string
    lossAmount: number
    lossSubcoverages: CalculationTermSubcoverageItem[]
    loss: Maybe<Loss>
  }[]

  subcoverages: CalculationTermSubcoverageItem[]
  lossesAmount: number
  excessAmount: number
  suggestedExcessAmount?: number
  copayAmount: number
  copayPercent: number
  overLimitAmount: number
  uncoveredItemsAmount: number
  subtotalAmount: number
  totalAmount: number
  subcoveragesTotal: CalculationTermSubcoverageItem[]

  overLimits: CoverageGroupedCalculationCoverageOverLimit[]
  uncoveredItems: CoverageGroupedCalculationCoverageUncoveredItem[]
}

export interface CoverageGroupedCalculationCoverageUncoveredItem {
  amount: number
  description?: string
  deduction_category?: string
  deduction_subcategory?: string
  additional_info?: string
  subcoverage?: string
}

export interface CoverageGroupedCalculationCoverageOverLimit {
  amount: number
  description?: string
  deduction_category?: string
  deduction_subcategory?: string
  additional_info?: string
  subcoverage?: string
}

export interface CoverageGroupedCalculationUnattributed {
  lossesAmount: number
  excessAmount: number
  copayAmount: number
  copayPercent: number
  overLimitAmount: number
  overLimits: CoverageGroupedCalculationCoverageOverLimit[]
  uncoveredItemsAmount: number
  uncoveredItems: CoverageGroupedCalculationCoverageUncoveredItem[]
  subtotalAmount: number
}

export function getIsLegacyCalculation(
  calculation: Partial<Calculation>
): boolean {
  return !(calculation.terms ?? []).every((term) => term.coverage)
}

export function getCoverageGroupedCalculation(
  calculation: Partial<Calculation>,
  losses: Loss[],
  deductCopayBeforeExcess: boolean
): CoverageGroupedCalculation {
  // We clone it because we will modify it
  calculation = cloneDeep(calculation)
  if (!Array.isArray(calculation.terms)) {
    calculation.terms = []
  }

  // If we have a term that doesn't have coverage then this is a legacy calculation
  const isLegacyCalculation = getIsLegacyCalculation(calculation)

  // Get list of unique loss ids
  const calculationLossesIds = [
    ...new Set(
      calculation.terms.map((term) => term.loss).filter(isNotNullOrUndefined)
    ),
  ]

  // Get all unique losses in this calculation
  const calculationLosses = calculationLossesIds
    .map((lossId) => losses.find((loss) => loss.id === lossId))
    .filter(isNotNullOrUndefined)

  // If it's legacy calculation with only one loss, then add the coverage to all terms
  // as if it's a coverage-grouped calculation
  if (isLegacyCalculation && calculationLossesIds.length === 1) {
    calculation.terms = calculation.terms.map((term) => ({
      ...term,
      coverage: calculationLosses[0].coverage,
      // loss: calculationLossesIds[0],
    }))
  }

  // Get list of unique coverages
  const calculationCoverages = [
    ...new Set(
      calculation.terms
        .map((term) => term.coverage)
        .filter(isNotNullOrUndefined)
    ),
  ]

  return {
    coverages: calculationCoverages.map((coverage) =>
      getCoverageGroupedCalculationCoverage(
        coverage,
        calculationLosses.filter((loss) => loss.coverage === coverage),
        calculation,
        deductCopayBeforeExcess
      )
    ),
    totalAmount: calculation?.amount ?? 0, // ?? sumBy(lossCalcs, 'totalAmount'),
    lossesAmount: sumBy(calculationLosses, 'amount'),

    // If this is a legacy calculation with more than one loss then we use the
    // "unattributed" property to store this legacy calculation info.
    unattributed:
      isLegacyCalculation && calculationLossesIds.length > 1
        ? getCoverageGroupedCalculationUnattributed(
            calculation,
            deductCopayBeforeExcess
          )
        : undefined,
  }
}

export function getCoverageGroupedCalculationCoverage(
  coverage: string,
  coverageLosses: Loss[],
  calculation: Partial<Calculation>,
  deductCopayBeforeExcess: boolean
): CoverageGroupedCalculationCoverage {
  const terms = calculation?.terms ?? []
  const coverageTerms = terms.filter((term) => term.coverage === coverage)
  const lossTerms = getTermsByCategory(coverageTerms, 'LOSS')
  if (!lossTerms.length) {
    throw new Error('Could not find LOSS term in calculation')
  }

  const lossItems = lossTerms.map((term) => {
    const loss = coverageLosses.find((l) => l.id === term.loss)
    return {
      loss,
      lossAmount: term.amount,
      lossId: term.loss!,
      lossRef: loss?.ref ?? getLossRefFromId(term.loss!),
      lossSubcoverages: term.subcoverages ?? [],
    }
  })

  const overLimitTerms = getTermsByCategory(coverageTerms, 'OVER_LIMIT')
  const uncoveredItemsTerm = getTermsByCategory(
    coverageTerms,
    'UNCOVERED_ITEMS'
  )

  // Amounts
  const lossesAmount = sumBy(lossTerms, 'amount')
  const subcoverages = uniqBy(
    lossTerms.flatMap((term) => term.subcoverages ?? []),
    'name'
  )
  const subcoveragesTotal =
    subcoverages.map((subcoverage) => {
      return {
        name: subcoverage.name,
        amount: sumBy(
          coverageTerms.flatMap((term) =>
            term.subcoverages?.filter(
              (subcoverageItem) => subcoverageItem.name === subcoverage.name
            )
          ),
          'amount'
        ),
      }
    }) ?? []
  const excessAmount = getTermByCategory(coverageTerms, 'EXCESS')?.amount ?? 0
  const suggestedExcessAmount = getTermByCategory(
    coverageTerms,
    'EXCESS'
  )?.suggested_amount
  const copayAmount = getTermByCategory(coverageTerms, 'COPAY')?.amount ?? 0
  const overLimitAmount = sumBy(overLimitTerms, 'amount')
  const uncoveredItemsAmount = sumBy(uncoveredItemsTerm, 'amount')
  const subtotalAmount = deductCopayBeforeExcess
    ? lossesAmount + uncoveredItemsAmount
    : lossesAmount + uncoveredItemsAmount + excessAmount

  // Co-pay percent/amount and total
  let copayPercent: number
  if (isCalculationNew(calculation)) {
    // If it's a new calculation then compute the co-pay amount from the co-pay percent
    copayPercent = getTermByCategory(coverageTerms, 'COPAY')?.percent ?? 0
  } else {
    // If it's an already existing calculation then compute the co-pay percent from the co-pay amount
    copayPercent = Number(
      Math.abs((copayAmount / subtotalAmount) * 100).toFixed(2)
    )
  }

  const totalAmount = sumBy(coverageTerms, 'amount')

  return {
    coverage,
    lossItems,
    lossesAmount,
    subcoverages,
    excessAmount,
    suggestedExcessAmount,
    copayAmount,
    copayPercent,
    overLimitAmount,
    uncoveredItemsAmount,
    subtotalAmount,
    totalAmount,
    subcoveragesTotal,

    overLimits: overLimitTerms.map((term) => ({
      ...term,
      subcoverage: term.subcoverages?.[0]?.name, // By design we only have one subcoverage per term
    })),
    uncoveredItems: uncoveredItemsTerm.map((term) => ({
      ...term,
      subcoverage: term.subcoverages?.[0]?.name, // By design we only have one subcoverage per term
    })),
  }
}

export function getReimbursementTotalAmount(
  calculations: Calculation[]
): number {
  return sumBy(
    calculations.filter(
      (c) => isCalculationAccepted(c) && c.amount && c.amount >= 0
    ),
    'amount'
  )
}

export function getExcessTotalAmount(calculations: Calculation[]): number {
  const acceptedCalculations = calculations.filter(isCalculationAccepted)
  const acceptedExcessTerms = getTermsByCategory(
    acceptedCalculations.flatMap((c) => c.terms),
    'EXCESS'
  )

  // Get reimbursement total amount so we can compare it with the excesses
  const reimbursementTotalAmount = sumBy(acceptedCalculations, 'amount')

  // Get sum of all excesses from calculations, and turn it into a positive number
  const totalExcessEntered = Math.abs(sumBy(acceptedExcessTerms, 'amount'))

  // In the US the whole excess balance is put into the calculation
  // but we have to count only the used excess in case of a negative calc amount
  if (totalExcessEntered > 0 && reimbursementTotalAmount < 0) {
    return totalExcessEntered + reimbursementTotalAmount
  }

  return totalExcessEntered
}

export function getCalculationLosses(
  calculation: Partial<Calculation>,
  losses: Loss[]
): Loss[] {
  const calculationLossIds = getTermsByCategory(
    calculation.terms ?? [],
    'LOSS'
  ).map((term) => term.loss)

  return losses.filter((l) => calculationLossIds.includes(l.id))
}

export function getCalculationPayeeNames(
  calculation: Partial<Calculation>,
  losses: Loss[]
): Maybe<string[]> {
  const payeeNames: string[] = []

  const calculationLosses = getCalculationLosses(calculation, losses)

  calculationLosses.forEach((loss) => {
    let payeeName = startCase(loss.payee?.payee_type?.toLowerCase())
    if (payeeName) {
      // Append Vet name if payee is Vet
      const payeeVetName = getLossPayeeVetName(loss)
      if (payeeVetName) {
        payeeName = `${payeeName} (${payeeVetName})`
      }
      payeeNames.push(payeeName)
    }
  })

  return uniq(payeeNames)
}

export function getCalculationPayees(
  calculation: Partial<Calculation>,
  losses: Loss[]
): LossPayee[] {
  const calculationLosses = getCalculationLosses(calculation, losses)
  return uniqBy(
    calculationLosses.map((loss) => loss.payee).filter(isNotNullOrUndefined),
    'payee_id'
  )
}

export function getCoverageGroupedCalculationUnattributed(
  calculation: Partial<Calculation>,
  deductCopayBeforeExcess: boolean
): CoverageGroupedCalculationUnattributed {
  const terms = calculation?.terms ?? []
  const lossesAmount = sumBy(getTermsByCategory(terms, 'LOSS'), 'amount')

  const overLimitTerms = getTermsByCategory(terms, 'OVER_LIMIT')
  const uncoveredItemsTerm = getTermsByCategory(terms, 'UNCOVERED_ITEMS')

  // Amounts
  const excessAmount = getTermByCategory(terms, 'EXCESS')?.amount ?? 0
  const copayAmount = getTermByCategory(terms, 'COPAY')?.amount ?? 0
  const overLimitAmount = sumBy(overLimitTerms, 'amount')
  const uncoveredItemsAmount = sumBy(uncoveredItemsTerm, 'amount')
  const subtotalAmount = deductCopayBeforeExcess
    ? lossesAmount + uncoveredItemsAmount
    : lossesAmount + uncoveredItemsAmount + excessAmount

  // Round the copay percent to 2 decimal places
  const copayPercent = Number(
    Math.abs((copayAmount / subtotalAmount) * 100).toFixed(2)
  )

  return {
    lossesAmount,
    excessAmount,
    copayAmount,
    copayPercent,
    overLimitAmount,
    uncoveredItemsAmount,
    subtotalAmount,

    overLimits: overLimitTerms,
    uncoveredItems: uncoveredItemsTerm,
  }
}
