import { OrderOperationType, OrderStatus } from '@/domain/Order'
import { OrderListItem } from '@/domain/OrderListItem'

import ProductPriceParser, { ProductDeltaPriceJson } from './ProductPriceParser'

export interface OrderList {
  orders: OrderListItemJson[]
}

export interface OrderListItemJson {
  id: string
  effective_date?: string
  updated_at?: string
  operation_type: OrderOperationType
  purchase_status: OrderStatus
  price: ProductDeltaPriceJson
}

export class OrderListParser {
  public static parse(data: OrderList): OrderListItem[] {
    return data.orders.map(OrderListParser.parseOrderListItem)
  }

  public static parseOrderListItem(data: OrderListItemJson): OrderListItem {
    return {
      id: data.id,
      effectiveDate: data.effective_date,
      updatedAt: data.updated_at,
      operationType: data.operation_type,
      status: data.purchase_status,
      price: ProductPriceParser.parseDeltaPrice(data.price),
    }
  }
}
