import { max, min, startCase, sumBy, uniq, uniqBy } from 'lodash-es'

import { Timespan } from '@/@types/Config'
import i18n from '@/i18n'
import { getConfigForFeature } from '@/lib/appConfig'
import dayjs from '@/lib/dayjs'
import { isNotNullOrUndefined } from '@/lib/utils/arrays'
import { formatDuration } from '@/lib/utils/duration'
import { formatDate } from '@/lib/utils/localisation'
import type { Calculation } from '@/novelClaims/domain/Calculation'
import type { Claim, ClaimDates } from '@/novelClaims/domain/Claim'
import type {
  Loss,
  LossVet,
  LossVetAlgolia,
  LossVetPractice,
  LossPayee,
  LossCondition,
} from '@/novelClaims/domain/Loss'
import { isVetFeesLoss } from '@/novelClaims/domain/Loss'
import type { PolicyPet, PolicyV2 } from '@/novelClaims/domain/PolicyV2'
import { isCalculationAccepted } from '@/novelClaims/utils/calculationUtils'
import { getPolicyTermOrdinalForDate } from '@/novelClaims/utils/policyUtils'

export function getCoverageName(coverage: string): string {
  return startCase(coverage)
}

export function getLossTitle(loss: Loss): string {
  if (isVetFeesLoss(loss)) {
    return (
      loss.data.accident.description ||
      loss.data.accident.description_of_illness_or_injury ||
      loss.data.accident.ailment_friendly ||
      loss.data.accident.ailment ||
      getCoverageName(loss.coverage)
    )
  }

  return getCoverageName(loss.coverage)
}

export function getClaimDates(claim: Claim): ClaimDates {
  const dols = claim.losses.map((l) => l.date_of_loss)
  const teds = claim.losses.map((l) => l.treatment_end_date)

  const minDOL = min(dols)
  const maxDOL = max(dols)
  const minTED = min(teds)
  const maxTED = max(teds)

  return {
    minDOL,
    maxDOL,
    minTED,
    maxTED,
    minTreatmentDate: min([minDOL, minTED]),
    maxTreatmentDate: max([maxDOL, maxTED]),
  }
}

export function getClaimContinuationType(claim: Claim): 'new' | 'continuation' {
  return claim.continuation_id && claim.continuation_id !== claim.id
    ? 'continuation'
    : 'new'
}

export function isActiveLoss(loss: Loss): boolean {
  return loss.status === 'OPEN' || loss.status === 'IN_PROGRESS'
}

export function hasActiveLosses(claim: Claim): boolean {
  return claim.losses.some(isActiveLoss)
}

export function lossHasDocuments(loss: Loss): boolean {
  return Boolean(loss.documents?.length)
}

export function lossHandledByCovea(
  loss: Loss,
  lossCalculations: Calculation[]
): boolean {
  if (loss.status !== 'APPROVED') {
    return false
  }

  if (loss.coverage === 'first_vet' || loss.coverage === 'consultation') {
    return false
  }

  if (lossCalculations.some(isCalculationAccepted)) {
    return false
  }

  return true
}

export function claimHasMedicalHistory(
  claim: Pick<Claim, 'evidence'>
): boolean {
  return Boolean(
    claim.evidence?.some(
      ({ type, status }) => type === 'MEDICAL_HISTORY' && status === 'APPROVED'
    )
  )
}

export function claimHasCoveaLosses(
  claim: Claim,
  claimCalculations: Calculation[]
): boolean {
  return claim.losses.some((loss) => {
    const lossCalculations = claimCalculations.filter((c) =>
      c.terms.some((t) => t.loss === loss.id)
    )

    return lossHandledByCovea(loss, lossCalculations)
  })
}

export function isLossVetLegit(lossVet: LossVet): boolean {
  if (isLossVetPractice(lossVet)) {
    return true
  }

  if (isLossVetAlgolia(lossVet)) {
    // Historically we've added default "Missing Vet" with id: 0, -1 or 138307(Covea) to indicate that the vet is missing
    // We don't set default "Missing Vet" vet anymore but we still need to cater for old claims
    if (lossVet.id === 0 || lossVet.id === -1 || lossVet.id === 138307) {
      return false
    }
  }

  return true
}

export function isLossVetAlgolia(lossVet: LossVet): lossVet is LossVetAlgolia {
  return 'id' in lossVet
}

export function isLossVetPractice(
  lossVet: LossVet
): lossVet is LossVetPractice {
  return !!lossVet.vet_practice_id
}

export function getRecentVets(lossVets: Maybe<LossVet>[]): LossVet[] {
  return uniqBy(
    lossVets.filter(isNotNullOrUndefined).filter(isLossVetPractice),
    'vet_practice_id'
  )
}

export function getRecentPayees(lossPayees: Maybe<LossPayee>[]): LossPayee[] {
  return uniqBy(
    lossPayees.filter(isNotNullOrUndefined).filter((p) => 'payee_id' in p),
    'payee_id'
  ) as LossPayee[]
}

export function getLossPayeeVetName(loss: Loss): Maybe<string> {
  if (
    loss.payee?.payee_type === 'VET' &&
    (loss.payee?.vet_practice_id || loss.payee?.vet_id)
  ) {
    let vet

    if (loss.payee?.vet_practice_id) {
      vet = loss.vets.find(
        (v) => v.vet_practice_id === loss.payee?.vet_practice_id
      )
    } else if (loss.payee?.vet_id) {
      vet = loss.vets.find((v) => v.id === loss.payee?.vet_id)
    }

    return vet?.practice_name ?? 'Undefined vet'
  }

  return undefined
}

export function getLossRefFromId(lossId: string): string {
  return lossId.substring(0, 10)
}

export function getClaimTotalAmount(claim: Claim): number {
  return sumBy(claim.losses, 'amount')
}

// Sorts the claim losses in place by DOL descending
export function sortClaimLosses(claim: Claim): void {
  claim.losses.sort((a, b) => {
    // Sort by DOL descending
    const diffDOL =
      new Date(b.date_of_loss).valueOf() - new Date(a.date_of_loss).valueOf()

    if (diffDOL) {
      return diffDOL
    }

    // If DOL is the same, sort by created_at descending
    const diffCreatedAt =
      new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()

    if (diffCreatedAt) {
      return diffCreatedAt
    }

    // If created_at is the same, sort by ID descending
    return a.id > b.id ? -1 : 1
  })
}
export function getClaimWaitingPeriodWarningMessages(params: {
  policy?: PolicyV2
  petUuid?: PolicyPet['uuid']
  dateOfLoss?: string
}): string[] {
  const warnings = []

  if (params.dateOfLoss && params.policy) {
    const endDates =
      params.policy.pets?.find((pet) => pet.uuid === params.petUuid)?.cover
        ?.waiting_period_end_dates ?? {}

    if (getConfigForFeature('claim-pet-waiting-period-messages.is-enabled')) {
      Object.entries(endDates)?.forEach(([product, endDate]) => {
        if (dayjs(params.dateOfLoss).isBefore(dayjs(endDate), 'day')) {
          warnings.push(
            `Policy is within the ${product} waiting period until ${formatDate(
              endDate
            )}`
          )
        }
      })
    } else {
      const minWarningTimespan = getConfigForFeature(
        'claims.minWarningTimespan',
        {
          productLine: params.policy.product_line,
        }
      ) as Timespan
      if (minWarningTimespan) {
        const minWarningTimespanDuration = dayjs.duration(
          minWarningTimespan.duration
        )

        if (
          dayjs(params.dateOfLoss)
            .subtract(minWarningTimespanDuration)
            .isBefore(
              dayjs(params.policy.cover.first_policy_year_start_date),
              'day'
            )
        ) {
          warnings.push(
            `Date is within ${formatDuration(
              minWarningTimespanDuration,
              minWarningTimespan.format as any
            )} of policy inception`
          )
        }
      }
    }
  }
  return warnings
}

export function getClaimPastPeriodWarningMessages(params: {
  policy?: PolicyV2
  dateOfLoss?: string
  createdAt?: string // undefined means NOW
}): string[] {
  const warnings = []
  if (params.policy) {
    // Set default timespan to 180 days.
    // This is the default for US, and UK policies incepting after 1st August 2024
    let timespan = 180

    if (params.policy.agency === 'BBM') {
      if (
        dayjs(params.policy.cover.policy_year_start_date).isBefore('2024-08-01')
      ) {
        timespan = 365
      }
    }

    if (
      params.dateOfLoss &&
      dayjs(params.dateOfLoss)
        .add(timespan, 'days')
        .isBefore(dayjs(params.createdAt), 'day')
    ) {
      warnings.push(
        i18n.global.t('claim.maxWarningMessage', {
          duration: `${timespan} days`,
        })
      )
    }
  }
  return warnings
}

export function getTreatmentDatesSpanMultipleYearsWarningMessages(params: {
  policy?: PolicyV2
  dateOfLoss?: string
  treatmentEndDate?: string
}): string[] {
  const warnings = []

  if (
    params.policy &&
    params.dateOfLoss &&
    params.treatmentEndDate &&
    getPolicyTermOrdinalForDate(params.policy, params.dateOfLoss) !==
      getPolicyTermOrdinalForDate(params.policy, params.treatmentEndDate)
  ) {
    warnings.push(
      i18n.global.t('claim.treatmentDatesSpanMultiplePolicyYearsWarningMessage')
    )
  }

  return warnings
}

export function getLossesConditions(losses: Loss[]): LossCondition[] {
  return uniqBy(
    losses.map((loss) => loss.condition).filter(isNotNullOrUndefined),
    'id'
  )
}

export function getLossesDocumentIDs(losses: Loss[]): string[] {
  return uniq(
    losses
      .flatMap((loss) => loss.documents)
      .filter(isNotNullOrUndefined)
      .map((doc) => doc.id)
  )
}
