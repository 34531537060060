/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { RouteLocationNormalized } from 'vue-router'

const lunaRoutes = [
  {
    path: '/luna',
    name: 'luna',
    component: (): Promise<any> => import('@/Luna/views/Luna'),
    children: [
      {
        path: 'categories',
        name: 'luna-categories',
        component: (): Promise<any> => import('@/Luna/views/LunaCategoryList'),
      },
      {
        path: 'scoreboard',
        name: 'luna-scoreboard',
        component: (): Promise<any> => import('@/Luna/views/LunaScoreboard'),
        meta: {
          title: 'Luna Scoreboard',
        },
      },
      {
        path: 'tasks',
        name: 'luna-tasklist',
        component: (): Promise<any> => import('@/Luna/views/LunaTaskList'),
        meta: {
          title: 'Luna Task List',
        },
      },
      {
        path: 'category/:currentCategory',
        name: 'luna-category',
        component: (): Promise<any> => import('@/Luna/views/LunaCategory'),
        props: (to: RouteLocationNormalized) => ({
          currentCategory: to.params.currentCategory as string,
        }),
        meta: {
          title: "Luna's Domain",
        },
      },
      {
        path: 'cse-dashboard',
        component: () =>
          import('@/Luna/views/LunaCseDashboard/LunaCseDashboardWrapper'),
        meta: {
          title: 'CSE Dashboard',
        },
        children: [
          {
            path: ':section?',
            name: 'luna-cse-dashboard',
            component: () =>
              import('@/Luna/views/LunaCseDashboard').then((m) => m.default),
            props: (to: RouteLocationNormalized) => ({
              section: to.params.section,
            }),
          },
        ],
      },
    ],
  },
]

export default lunaRoutes
