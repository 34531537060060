import jwt_decode from 'jwt-decode'
import { ref, computed } from 'vue'
import type { ComputedRef } from 'vue'

import { getConfigForFeature } from '@/lib/appConfig'
import { agency, instance } from '@/plrn'

const accessToken = ref('')

const permissions = computed<string[]>(() => {
  try {
    if (accessToken.value) {
      return (
        jwt_decode<Record<string, any>>(accessToken.value).permissions ?? []
      )
    }
  } catch {
    // Opaque tokens can't be decoded.
    // Auth0 returns them when we don't provide the "audience" - this happens
    // when we haven't selected instance/agency yet.
    console.debug(
      'Cannot determine user permissions. Invalid or "opaque" access token.'
    )
  }

  return []
})

// Returns the list of roles from the access token filtered by instance where applicable
// WARNING: These can't be relied on as definitive roles or for permissions
// as _all_ roles are returned in the access token, including those that are
// not applicable to the current instance/agency.
// We can just use them as a hint as to the role of the user.
const roles = computed<string[]>(() => {
  try {
    if (accessToken.value) {
      return (
        jwt_decode<Record<string, any>>(accessToken.value)[
          'https://policies.io/roles'
        ] ?? []
      ).filter((role: string) => {
        const roleLowerCase = role.toLowerCase()
        if (instance) {
          if (
            !roleLowerCase.includes('dev') && // covers dev, development
            !roleLowerCase.includes('stag') && // covers stag, staging
            !roleLowerCase.includes('prod') // cover prod, production
          ) {
            // Role isn't instance specific so we can return it
            return true
          }

          // Filter out the roles that don't match the instance
          const roleInstance = getConfigForFeature('isProductionInstance')
            ? 'prod'
            : 'stag'

          return role.includes(roleInstance)
        }

        return true // No instance selected, return all roles
      })
    }
  } catch {
    // Opaque tokens can't be decoded.
    // Auth0 returns them when we don't provide the "audience" - this happens
    // when we haven't selected instance/agency yet.
    console.debug(
      'Cannot determine user roles. Invalid or "opaque" access token.'
    )
  }

  return []
})

/**
 * Permissions
 */
const agencyPermissions = computed(() => {
  return permissions.value
    .filter((permissionName) => permissionName.split('/')?.[0] === agency)
    .map((permissionName) => permissionName.split('/')?.[2])
})

const isClaimSupervisor = computed(() => {
  return Boolean(permissions.value?.includes(`${agency}/*/claims:supervise`))
})

const userCanDeleteClaims = computed(() => {
  return Boolean(permissions.value?.includes(`${agency}/*/claims:delete`))
})

const userCanAssessCalculations = computed(() => {
  return Boolean(permissions.value?.includes('claims:assess'))
})

const userCanAssessOverLimitCalculations = computed(() => {
  return Boolean(permissions.value?.includes('claims:assess_over_limit'))
})

const userCanDeleteVetPractice = computed(() => {
  return Boolean(permissions.value?.includes('vets:delete'))
})

const userCanMigrateVetClaims = computed(() => {
  return Boolean(permissions.value?.includes('claims:migrate_vet'))
})

const userCanModifyVetUsers = computed(() => {
  return Boolean(permissions.value?.includes('vet_users:write'))
})

const userCanReinstatePolicies = computed(() => {
  return Boolean(
    permissions.value?.includes(`${agency}/*/policies/reinstatement:write`)
  )
})

const userCanUnsubscribe = computed(() => {
  return Boolean(
    permissions.value?.includes(`${agency}/*/customer:unsubscribe`)
  )
})

const userHasLunaPermissons = computed(() => {
  return Boolean(permissions.value.includes('luna:all'))
})

export const usePermissions = (): {
  accessToken: ComputedRef<string>

  /**
   * The user's roles.
   * DO NOT use this for permissions, use permissions instead
   */
  roles: ComputedRef<string[]>

  // Permissions
  permissions: ComputedRef<string[]>
  agencyPermissions: ComputedRef<string[]>
  isClaimSupervisor: ComputedRef<boolean>
  userCanDeleteClaims: ComputedRef<boolean>
  userCanAssessCalculations: ComputedRef<boolean>
  userCanAssessOverLimitCalculations: ComputedRef<boolean>
  userCanDeleteVetPractice: ComputedRef<boolean>
  userCanMigrateVetClaims: ComputedRef<boolean>
  userCanModifyVetUsers: ComputedRef<boolean>
  userCanReinstatePolicies: ComputedRef<boolean>
  userCanUnsubscribe: ComputedRef<boolean>
  userHasLunaPermissons: ComputedRef<boolean>
} => ({
  accessToken: computed(() => accessToken.value),
  // Roles
  roles,

  // Permissions
  permissions,
  agencyPermissions,
  isClaimSupervisor,
  userCanDeleteClaims,
  userCanAssessCalculations,
  userCanAssessOverLimitCalculations,
  userCanDeleteVetPractice,
  userCanMigrateVetClaims,
  userCanModifyVetUsers,
  userCanReinstatePolicies,
  userCanUnsubscribe,
  userHasLunaPermissons,
})

export const setPermissionsAccessToken = (token: string): void => {
  accessToken.value = token
}
