import type { InstanceConfig } from '@/@types/Config'
import { getConfigForFeature } from '@/lib/appConfig'
import dayjs, { Dayjs, DayJsParsable } from '@/lib/dayjs'
import { getOrdinal } from '@/lib/utils'
import { toLongUuid } from '@/lib/utils'
import { petAgeAtDate } from '@/lib/utils/petAge'
import type { Policy } from '@/novelClaims/domain/Policy'
import type {
  PolicyV2,
  PolicyCustomer,
  Coverage,
} from '@/novelClaims/domain/PolicyV2'

export function getPolicyId(policy: PolicyV2, petUuid: string): string {
  if (policy.product_line === 'cat-dog') {
    const pet = policy.pets.find((pet) => pet.uuid === petUuid)

    return pet?.policy_id ?? ''
  }

  return policy.id ?? ''
}

// Example: pol_Rr6tm9nXNsS2RbLku3xMqA -> 1031268000323068
export function getPolicyRefFromId(policyId: string): string {
  const decoded = toLongUuid(policyId).replaceAll('-', '')
  return `${decoded.slice(0, 8)}${decoded.slice(17, 25)}`
    .split('')
    .reverse()
    .join('')
}

export function getPolicyOwner(policy: PolicyV2): PolicyCustomer {
  return (
    policy.customers.find((customer) => customer.role === 'OWNER') ??
    policy.customers[0]
  )
}

export function getPolicyWarningCodes(policy: PolicyV2): string[] {
  const warnings = policy.warnings ?? []

  // We ignore some warnings that are not relevant to the user.
  const ignoreWarnings = ['INVALID_POLICY_ID']

  return warnings.filter((warning) => !ignoreWarnings.includes(warning))
}

export function getPolicyVetFeesCoverage(policy: PolicyV2): Maybe<Coverage> {
  return policy.coverages?.['vet_fees'] ?? policy.coverages?.['vet-fees']
}

/**
 * Returns the list of subcoverages for specific policy coverage that are supported for tracking (based on instanceConfig).
 *
 * @param {PolicyV2} policy The policy to get the supported coverage subcoverages for.
 * @param {string} coverage The coverage to get the supported subcoverages for.
 * @returns {string[]} The list of supported subcoverages sorted alphabetically.
 * @example
 *  getPolicyCoverages(policy, 'vet_fees') => ['complementary_treatment', 'prescribed_food']
 */
export function getPolicyCoverageSubcoverages(
  policy: PolicyV2,
  coverage: string
): string[] {
  // Gets the supported trackable coverages/subcoverages from config.
  const allowedTrackedCoveragesConfig =
    (getConfigForFeature(
      'trackedPolicyCoverageSubcoverages'
    ) as InstanceConfig['trackedPolicyCoverageSubcoverages']) ?? {}

  const allowedTrackedSubcoveragesNames =
    allowedTrackedCoveragesConfig[coverage] ?? []

  const policyCoverageSubcoverages = policy.coverages?.[coverage]?.limits ?? {}

  // Filter the subcoverages that are supported for tracking and are present on the policy.
  // We only track subcoverages that have a limit and the limit is greater than 0.
  // Follow the order set in config - trackedPolicyCoverageSubcoverages.
  return allowedTrackedSubcoveragesNames.filter(
    (subcoverageName) =>
      policyCoverageSubcoverages[subcoverageName]?.limit &&
      policyCoverageSubcoverages[subcoverageName]?.limit > 0
  )
}

/**
 * Returns the policy Pre-existing conditions definition/moratorium.
 *
 * @param {PolicyV2} policy The policy
 * @returns {string|undefined} The PX definition
 * @example
 *  getPolicyPreExDefinition(policy) -> 'Rolling'
 *  getPolicyPreExDefinition(policy) -> 'Before inception'
 */
export function getPolicyPreExDefinition(policy: PolicyV2): Maybe<string> {
  const vetFeeCoverage = getPolicyVetFeesCoverage(policy)

  const text = vetFeeCoverage?.limits?.px_moratorium?.condition

  if (text === '2 years - rolling') {
    return 'Rolling'
  }

  if (text === '2 years before inception') {
    return 'Before inception'
  }

  return text
}

/**
 * Multiply two numbers together in a null-safe way, coercing the result to an integer.
 *
 * @param {number} [value] The multiplicand.
 * @param {number} [multiplier=1] The multiplier to multiply the multiplicand by.
 * @returns {number?} The two parameters multiplied together (and coerced to an integer)
 * if a multiplicand was given. If not, null.
 */
function getMultipliedIntegerOrNull(
  value?: Nullable<number>,
  multiplier: number = 1
): Nullable<number> {
  if (value != null) {
    return parseInt((value * multiplier).toString())
  }
  return null
}

/**
 * Multiply two numbers together in a null-safe way, coercing the result to a float.
 *
 * Note: I have no idea why this function coerces the result of a multiplication to a
 * float, but it does. Probably best not to think about it.
 *
 * @param {number} [value] The multiplicand.
 * @param {number} [multiplier=1] The multiplier to multiply the multiplicand by.
 * @returns {number?} The two parameters multiplied together (and coerced to a float)
 * if a multiplicand was given. If not, null.
 */
function getMultipliedFloatOrNull(
  value?: Nullable<number>,
  multiplier: number = 1
): Nullable<number> {
  if (value != null) {
    return parseFloat((value * multiplier).toString())
  }
  return null
}

export interface PolicyProperties {
  deductibles: {
    copay?: Nullable<number>
    copay_pre_existing_condition?: Nullable<number>
    excess?: Nullable<number>
    excess_pre_existing_condition?: Nullable<number>
  }
  limits: {
    annual_vet_fee?: Nullable<number>
  }
}

export function getPolicyProperties(
  policy: PolicyV2,
  petUuid: string
): PolicyProperties {
  const pet = policy.pets.find((pet) => pet.uuid === petUuid)

  const result: PolicyProperties = {
    deductibles: {
      excess: getMultipliedIntegerOrNull(pet?.cover?.excesses?.excess, 100),
      copay: getMultipliedIntegerOrNull(pet?.cover?.excesses?.co_payment),
    },
    limits: {},
  }

  // TODO: we should keep an eye for when Underwriting include the pre-ex excess/copay
  // on pet level (similar to what they did for the regular excess/copay).
  // When that happens, a lot of the code below can be simplified.
  // For now, we need to calculate the pre-ex excess/copay from policy.coverages level.
  const petAge = petAgeAtDate(
    pet?.date_of_birth,
    policy?.cover.policy_year_start_date
  )!

  const vetFeesCoverage = getPolicyVetFeesCoverage(policy)

  const preExistingConditionExcess =
    petAge.years >= 9
      ? vetFeesCoverage?.excesses?.px_mandatory_over_9?.amount
      : vetFeesCoverage?.excesses?.px_mandatory_under_9?.amount

  result.deductibles.excess_pre_existing_condition = getMultipliedIntegerOrNull(
    preExistingConditionExcess,
    100
  )

  let preExistingConditionCopayment =
    petAge.years >= 9
      ? vetFeesCoverage?.excesses?.px_copayment_over_9_percent?.amount
      : vetFeesCoverage?.excesses?.px_copayment_under_9_percent?.amount

  // If the policy was created or renewed after 01.09.2022 then we need ignore the pre-ex copay percent.
  // This is because we have some bad data in IBA which shows pre-ex copay percent where there should be none.
  // This should be just a temporary fix in the FE (while the BE is busy) but in
  // the beginning of the next term (Jan 2023) we should ask Policy Squad to add
  // this logic in the IBA middleware service and remove this from FE.
  if (
    policy.product_line === 'cat-dog' &&
    dayjs(policy.cover.policy_year_start_date).isSameOrAfter(
      '2022-09-01',
      'day'
    )
  ) {
    preExistingConditionCopayment = undefined
  }

  result.deductibles.copay_pre_existing_condition = getMultipliedIntegerOrNull(
    preExistingConditionCopayment
  )

  // limits
  if (policy.product_line === 'cat-dog') {
    result.limits.annual_vet_fee = getMultipliedFloatOrNull(
      vetFeesCoverage?.allowances?.['per-year']?.amount,
      100
    )
  } else {
    result.limits.annual_vet_fee =
      (vetFeesCoverage?.limits?.annual?.limit as number) ?? null
  }

  return result
}

export function getPolicyOriginalStartDate(policyV1: Policy): string {
  // TODO: when "policy_original_start_date" is missing, we have a hacky fix
  // that calculates the original start date based on the "renewal_count" and
  // "inception_date".
  // We should remove this hacky fix when BE fixes this on their end and
  // "policy_original_start_date" is guaranteed to be there for all policies.

  const originalStartDate = policyV1?.metadata?.policy_original_start_date
  const renewalCount = policyV1?.renewal_count || 0
  const inceptionDate =
    policyV1?.data?.policy?.inception_date ?? policyV1?.inception_date

  if (originalStartDate) {
    return originalStartDate
  } else if (inceptionDate) {
    return dayjs
      .utc(inceptionDate)
      .subtract(renewalCount, 'year')
      .format('YYYY-MM-DD')
  } else {
    throw new Error('Cannot determine the policy original start date')
  }
}

// TODO: this function should be turned into a global util function to be used
// by both Claims and CSE (though CSE might stop showing "Policy Year" in the future).
// But for this to happen, we need to stop using PolicyV1 because calculating the
// policy term number for PolicyV1 is unreliable due to inconsistent datetimes.
// At the time of writing we need this to work for Claims which only use PolicyV2.
// One of the reasons we don't pass a PolicyV2 straight away (but instead a "policyCover"
// object) is to make it a bit more generic and future-proof.
export function getPolicyTermNumberForDate(
  policyCover: {
    status: string
    originalStartDate: string
    inceptionDate: string
    cessationDate: string
  },
  date?: DayJsParsable
): Nullable<number> {
  const originalStartDate = dayjs(policyCover.originalStartDate)
  const inceptionDate = dayjs(policyCover.inceptionDate)
  const cessationDate = dayjs(policyCover.cessationDate)
  let asOfDate = dayjs(date)

  if (!asOfDate.isSameOrAfter(originalStartDate)) {
    console.error(
      `Queried date ${date} is before the start date of the policy ${policyCover.originalStartDate}`
    )
    return null
  }

  function getSubscriptionStartFromDate(date: Dayjs): Dayjs {
    let result = originalStartDate.month(date.month()).date(date.date())

    if (result.isAfter(originalStartDate)) {
      result = result.year(originalStartDate.year() - 1)
    }

    return result
  }

  // Some product lines(pet-insurance-uk) allow for the initial policy term to be less than a year.
  // This happens when customers add new pet/policy to their existing subscription midterm.
  // In order to properly calculate the policy year, we need to determine what would be the
  // original start date if the first term was full year.
  // See the diagram in this PR https://github.com/boughtbymany/app.policies.io/pull/2017
  let subscriptionStart = originalStartDate.clone()
  const isFirstTerm = originalStartDate.isSame(inceptionDate, 'day')
  const isCancelled = ['VOID', 'CANCELLED'].includes(policyCover.status)

  if (isFirstTerm) {
    const isFirstTermLessThanAYear =
      cessationDate.diff(inceptionDate, 'year') < 1

    if (isFirstTermLessThanAYear) {
      // When first term is less than a year and the policy is cancelled, we
      // can't determine the subscriptionStart but we know it's always 1st year
      // so we return early
      if (isCancelled) {
        return 1
      }

      // When first term is less than a year and it's not cancelled, then we know
      // we can determine the subscriptionStart using the cessation date
      subscriptionStart = getSubscriptionStartFromDate(cessationDate)
    }
  } else {
    const wasFirstTermLessThanAYear =
      originalStartDate.month() !== inceptionDate.month() ||
      originalStartDate.date() !== inceptionDate.date()

    if (wasFirstTermLessThanAYear) {
      // When originalStartDate and inceptionDate are not the same month and day,
      // then this is a policy which first term is less than a year.
      // Since this is not the first term, then we can rely on the inception date
      // to determine the subscriptionStart date
      subscriptionStart = getSubscriptionStartFromDate(inceptionDate)
    }
  }

  if (isCancelled && asOfDate.isAfter(cessationDate)) {
    asOfDate = cessationDate.subtract(1, 'day')
  }

  // Add 1 to the year, as year diff will always round down.
  return asOfDate.diff(subscriptionStart, 'year') + 1
}

export function getPolicyTermOrdinalForDate(
  policy: Maybe<PolicyV2>,
  date: DayJsParsable
): string {
  if (!policy) {
    return 'unknown'
  }

  const policyTerm = getPolicyTermNumberForDate(
    {
      status: policy.status,
      originalStartDate: policy.cover.first_policy_year_start_date,
      inceptionDate: policy.cover.policy_year_start_date,
      cessationDate: policy.cover.policy_year_end_date,
    },
    date
  )

  return policyTerm ? getOrdinal(policyTerm) : 'unknown'
}
