{
  "postcode": "Postcode",
  "county": "County",
  "customer": {
    "activity": {
      "title": "Activity",
      "upcoming": "Upcoming",
      "noResults": "There are no upcoming activities"
    }
  },
  "color": "Colour",
  "general": {
    "title": "Title",
    "firstName": "First name",
    "lastName": "Last name",
    "dateOfBirth": "Date of birth",
    "email": "Email",
    "address": "Address",
    "addressLine1": "Line 1",
    "addressLine2": "Line 2",
    "city": "City",
    "county": "County",
    "country": "Country",
    "defaultSelect": "Select...",
    "years": "years",
    "months": "months",
    "or": "or",
    "Pets": "Pets",
    "Policies": "Policies"
  },
  "showMorePagination": {
    "showAll": "Show all",
    "showLess": "Show less",
    "showMore": "Show {pageSize} more",
    "shownResults": "Showing {shownCount} of {totalCount} results"
  },
  "quote": {
    "new": {
      "ageToday": "Age (today)"
    }
  },
  "policy": {
    "co-payment": "Co-payment",
    "co_payment": "Co-payment",
    "reimbursement": "Reimbursement",
    "excess": "Excess",
    "deductible": "Deductible",
    "deducted": "Deducted",
    "claimed": "Claimed",
    "cooling-off-warning": "Policy is in cooling-off period",
    "limits": {
      "amountClaimed": "{amount} claimed"
    },
    "warnings": {
      "INVALID_TERM": {
        "title": "Policy term seems incorrect, balances may be inaccurate.",
        "description": "Please follow the incorrect policy term process."
      },
      "MIGRATED": {
        "title": "Customer has migrated products",
        "description": "This customer's products have been migrated from IBSuite to SGP. Hopefully everything's fine, but if you notice anything strange, please raise a Halo ticket."
      }
    }
  },
  "claim": {
    "subcoverages": {
      "complementary_treatment": {
        "title": "Complementary Treatment",
        "short": "CT"
      },
      "cruciate": {
        "title": "Cruciate",
        "short": "CR"
      },
      "dental_accident": {
        "title": "Dental Accident",
        "short": "DA"
      },
      "mri_and_ct_scans": {
        "title": "MRI and CT Scans",
        "short": "MRI"
      },
      "prescribed_food": {
        "title": "Prescribed Food",
        "short": "PF"
      }
    },
    "payee": {
      "payeeType": "Payee type",
      "paymentMethodHeader": "Payment method",
      "paymentType": "Payment type",
      "accountHolderName": "Account holder name",
      "accountType": "Account type",
      "accountName": "Bank name",
      "accountPurpose": "Account purpose",
      "accountNumber": "Account number",
      "routingNumber": "Routing number",
      "sortCode": "Sort code",
      "addressLine1": "Address line 1",
      "addressLine2": "Address line 2",
      "addressLine3": "Address line 3",
      "city": "City",
      "usState": "State",
      "zipCode": "Zip code",
      "paymentTypeOptions": {
        "USAAccount": "Direct deposit",
        "USAAddress": "Check",
        "UKAccount": "Bank transfer",
        "SwedenAccount": "Bank transfer"
      },
      "accountTypeOptions": {
        "PERSONAL": "Personal",
        "BUSINESS": "Business"
      },
      "accountPurposeOptions": {
        "CHECKING": "Checking",
        "SAVINGS": "Savings"
      }
    },
    "audit": {
      "event": {
        "loss_approved": "Loss Approved",
        "loss_closed": "Loss Closed",
        "loss_completed": "Loss Created",
        "loss_declined": "Loss Declined",
        "loss_reopened": "Loss Reopened",
        "loss_in_progress": "Loss In Progress",
        "loss_additional_info_updated": "Loss Additional Info Updated",
        "loss_amount_updated": "Loss Amount Updated",
        "loss_cause_of_loss_updated": "Loss Cause of Loss Updated",
        "loss_condition_updated": "Loss Condition Updated",
        "loss_description_updated": "Loss Description Updated",
        "loss_treatment_end_date_updated": "Treatment End Date Updated",
        "date_of_loss_updated": "Date of Loss Updated",
        "loss_document_added": "Loss Document Added",
        "loss_document_removed": "Loss Document Removed",
        "calculation_approved": "Calculation Approved",
        "calculation_created": "Calculation Created",
        "payee_updated": "Payee Updated",
        "loss_treatment_updated": "Loss Treatment Updated",
        "case_title_updated": "Case Title Updated",
        "loss_added_to_case": "Loss Added to Case",
        "note_added_to_case": "Note Added to Case",
        "document_linked_to_loss": "Document Linked to Loss",
        "document_unlinked_from_loss": "Document Unlinked from Loss",
        "vets_assigned_to_loss": "Loss Vets Updated",
        "excess_balance_adjusted": "Excess Balance Adjusted",
        "loss_data_field_updated": {
          "accident/type": "Loss Accident Type Updated",
          "claim_against_policy_holder": "Loss Claim Against Policy Holder Updated",
          "history_of_aggression": "Loss History Of Aggression Updated",
          "previous_accident": "Loss Previous Accident Updated",
          "other_covering_policies": "Loss Other Covering Policies Updated",
          "prejudice_payment_details": "Loss Prejudice Payment Details Updated",
          "police_force_involved": "Loss Police Force Involved Updated",
          "responsible_party": "Loss Responsible Party Details Updated",
          "third_party_contacts": "Loss Third Party Contacts Updated"
        },
        "loss_subcoverage_amount_updated": "Loss {subcoverage} Amount Updated",
        "subcoverage_balance_adjusted": "{subcoverage} Balance Adjusted"
      },
      "by": "By",
      "info_title": "Description",
      "calculation": "Calculation",
      "loss": "Loss",
      "losses": "Losses",
      "case": "Case",
      "reason": "Reason",
      "note": "Note",
      "lossAdditionalInfo": "Loss Additional Info",
      "lossAmount": "Loss Amount",
      "oldLossAmount": "Old Loss Amount",
      "lossRecoveredAmount": "Loss Recovered Amount",
      "lossCauseOfLoss": "Loss Cause Of Loss",
      "lossDescription": "Loss Description",
      "lossCondition": "Loss Condition",
      "preExistingCondition": "Pre-existing Condition",
      "group": "Group",
      "section": "Section",
      "subgroup": "Subgroup",
      "documents": "Loss Documents",
      "document_id": "Loss Document Id",
      "file_name": "File name",
      "url": "URL",
      "treatments": "Treatments",
      "lossDateOfLoss": "New Date Of Loss",
      "lossPrevDateOfLoss": "Old Date Of Loss",
      "lossNewCarrier": "New Carrier",
      "lossPrevCarrier": "Previous Carrier",
      "updatedVets": "Updated Vets",
      "oldVets": "Old Vets",
      "newDataFieldValue": "New Value",
      "oldDataFieldValue": "Old Value",
      "amount": "Amount",
      "prevBalance": "Previous Balance",
      "newBalance": "New Balance",
      "description": "Description",
      "treatmentEndDate": "New Treatment End Date",
      "oldTreatmentEndDate": "Old Treatment End Date",
      "forceOverLimit": "Approved over limit",
      "subcoverageAmount": "Subcoverage Amount",
      "oldSubcoverageAmount": "Old Subcoverage Amount"
    },
    "document": {
      "type": {
        "MEDICAL_HISTORY": "Medical history",
        "MEDICAL_HISTORY_18_MONTHS": "Medical history - 18 month review",
        "INVOICE": "Invoice",
        "DOCTOR_OF_VET_MEDICINE_LETTER": "DVM",
        "REFERRAL_REPORT": "Referral report",
        "TREATMENT_NOTE": "Treatment note",
        "PRESCRIPTION": "Prescription",
        "ESTIMATE": "Estimate",
        "RECEIPT": "Receipt",
        "OTHER": "Other"
      }
    },
    "duplicateLoss": {
      "reasons": {
        "INVOICE_FROM_PREVIOUSLY_PAID_CLAIM": "Invoice from previously paid claim",
        "TREATMENT_DATE_OVERLAP": "Treatment date overlap"
      }
    },
    "millieLossStatus": {
      "errorNames": {
        "EntityNotInsuredByPolicy": "Pet not insured by policy",
        "LossManuallyHandled": "Loss has been manually handled",
        "PolicyHasOpenCalculations": "Claim with open calculations found on policy",
        "PolicyLocked": "Millie is processing another claim on this policy",
        "PolicyNotActiveAtDate": "Policy not active at DOL"
      }
    },
    "maxWarningMessage": "Date is more than {duration} before today",
    "treatmentDatesSpanMultiplePolicyYearsWarningMessage": "Treatment dates span multiple policy years",
    "viewAlgoliaVet": "Get postcode"
  },
  "vetPractice": {
    "practice_types": {
      "GENERAL_PRACTICE": "General practice",
      "OUT_OF_HOURS_PROVIDER": "Out of hours provider",
      "REFERRAL_CENTRE": "Referral centre",
      "BEHAVIOUR_SPECIALIST": "Behaviour specialist",
      "COMPLEMENTARY_THERAPIST": "Complementary therapist",
      "CORPORATE_OFFICE": "Corporate office",
      "PHARMACY": "Pharmacy"
    }
  }
}
